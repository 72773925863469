import React, {useEffect, useState} from 'react';
import {auth, db} from '../../firebase/firebase';
import {doc, getDoc} from "firebase/firestore";

export default function Dashboard() {
    //@ts-ignore
    const [event, setEvent] = useState(null)

    const logout = () => {
        auth.signOut().then(() => {
            window.location.href = "/";
        }).catch((error) => {
            console.log(error);
        });
    }


    useEffect(()=>{
        loadEvent()
    }, [])

    // useEffect(()=>{
    //     console.log(event)
    // },[event])

    const loadEvent = async ()=>{
        const docRef = doc(db, "tenant", "root-05fr7", "event", "MMISL2022");
        const d = await getDoc(docRef)
        setEvent(d.data())
        // console.log(d.data())
    }
    return (
        <div>
            <h1 className={""}>Dashboard</h1>
            <button onClick={logout}>Logout</button>
        </div>
    );
}
