import React, {useEffect, useState} from 'react';
import "./Navbar.css";
import {router} from "../../router";
import logo from '../../assets/logo.png'

export default function Navbar() {

    const [active, setActive] = useState('')

    useEffect(()=>{
        router.subscribe((state) => {
            setActive(state.location.pathname)
        })
    })

    const isActive = (path) => {
        const classes = 'nav-item'
        if(active === path || router.state.location.pathname === path){
            return classes + ' active'
        }else{
            return classes
        }
    }

    const navigate = (path) => {
        return () => {
            router.navigate(path)
        }
    }

    return(
        <div className={'header'}>
            <div className={'container'}>
                <img className={'logo'} src={logo} alt={'logo'}/>
                <span onClick={navigate('/')} className={isActive('/')}>Dashboard</span>
                <span onClick={navigate('/locations')} className={isActive('/locations')}>Locations</span>
                <span onClick={navigate('/events')} className={isActive('/events')}>Events</span>
                <span onClick={navigate('/staff')} className={isActive('/staff')}>Staff</span>
            </div>
        </div>
    )
}
