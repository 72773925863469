import React, {useEffect, useState} from "react";
import './Staff.css'
import {doc, getDoc, setDoc} from "firebase/firestore";
import {db, storage} from "../../firebase/firebase";
import {ref, uploadBytes, getDownloadURL} from "firebase/storage";
import {uuidv4} from "../../helpers";

export default function Staff(){

    const [staff, setStaff] = useState([])
    const [loading, setLoading] = useState(true)
    const [newS, setNewS] = useState({
        name: "",
        title: "",
        description: "",
        imageUrl: "",
        id: uuidv4()
    })

    useEffect(()=>{
        getStaff()
    },[])

    const getStaff = async () => {
        setLoading(true)
        const d = doc(db, "tenant", "root-05fr7", "event", "MMISL2022", "docs", "Staff")
        const result = await getDoc(d)
        setStaff(result.data())
        setLoading(false)
    }

    const updateStaff = async () => {
        setLoading(true)
        const d = doc(db, "tenant", "root-05fr7", "event", "MMISL2022", "docs", "Staff")
        await setDoc(d, staff, {merge: true})
        setLoading(false)
    }

    const setStaffValue = (id, field, e) => {
        const s = staff.data.find((s) => s.id === id)
        s[field] = e.target.value
        setStaff({...staff})
    }

    const addStaff = () => {
        const arr = staff.data
        arr.unshift(newS)
        setStaff({...staff, data: arr})
        setNewS({
            name: "",
            title: "",
            description: "",
            imageUrl: "",
            id: uuidv4()
        })
    }

    const uploadPhoto = async (id,e) => {
        const file = e.target.files[0]
        const storageRef = ref(storage, 'staff/' + id);

// 'file' comes from the Blob or File API
        uploadBytes(storageRef, file).then(async (snapshot) => {
            const s = staff.data.find((s) => s.id === id)
            s.imageUrl = await getDownloadURL(snapshot.ref)
            setStaff({...staff})
        });

    }

    const deleteStaff = (id) => {
        const arr = staff.data.filter((s) => s.id !== id)
        setStaff({...staff, data: arr})
    }

    const moveUp = (id) => {
        const arr = staff.data
        const index = arr.findIndex((s) => s.id === id)
        if(index === 0){
            return
        }
        const f = arr.splice(index, 1)[0];
        arr.splice(index-1, 0, f)
        setStaff({...staff, data: arr})
    }

    const moveDown = (id) => {
        const arr = staff.data
        const index = arr.findIndex((s) => s.id === id)
        if(index === arr.length-1){
            return
        }
        const f = arr.splice(index, 1)[0];
        arr.splice(index+1, 0, f)
        setStaff({...staff, data: arr})
    }

    return(
        <div className={'staff-container'}>
            <h1>Staff</h1>
            <div>Photos should be square to upload and display correctly</div>
            <button onClick={updateStaff}>Save</button>
            <button className={'ml-3'} onClick={addStaff}>+Add</button>
            <div className={'staff-content'}>

                {loading && <h1>Loading...</h1>}
                {!loading && staff && staff.data.length > 0 && staff.data.map((s, i) =>
                    <div key={s.id} className={'staff-row'}>
                        <div className={'arrows'}>
                            <div className={'arrow-btn'}>
                                <button onClick={()=>{moveUp(s.id)}}>^</button>
                            </div>
                            <div className={'arrow-btn'}>
                                <button onClick={()=>{moveDown(s.id)}} className={'arrow-down'}>^</button>
                            </div>
                        </div>
                        <div>
                            {!s.imageUrl ?
                            <input className={'file'} type={'file'} onChange={(e)=>uploadPhoto(s.id,e)}></input>:
                            <img className={'avatar'} src={s.imageUrl}/>
                            }
                        </div>
                        <div>
                            <input placeholder={'Name'} onChange={(e) => setStaffValue(s.id, 'name', e)} value={s.name}/>
                        </div>
                        <div>
                            <input placeholder={'Title'} onChange={(e) => setStaffValue(s.id, 'title', e)} value={s.title}/>
                        </div>
                        <div>
                            <input placeholder={'Description'} onChange={(e) => setStaffValue(s.id, 'description', e)} value={s.description}/>
                        </div>

                        <div className={'button-container'}>
                            <button onClick={()=>deleteStaff(s.id)}>-</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
