import {createBrowserRouter} from "react-router-dom";
import Dashboard from "../routes/dashboard/Dashboard";
import Staff from "../routes/staff/Staff";
import Events from "../routes/events/Events";
import Locations from "../routes/locations/Locations";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Dashboard />,
    },
    {
        path: '/locations',
        element: <Locations />
    },
    {
        path: '/events',
        element: <Events />
    },
    {
        path: '/staff',
        element: <Staff />
    }
])
