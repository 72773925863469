import React, {useEffect} from 'react';
import {collection, doc, getDoc, getDocs, setDoc} from "firebase/firestore";
import {db} from "../../firebase/firebase";
import './Events.css'
import {uuidv4} from "../../helpers";

export default function Events() {
    const [customE, setCustomeE ] = React.useState({});
    const [comps, setComps] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [addingComp, setAddingComp] = React.useState(false);
    //@ts-ignore
    const [error, setError] = React.useState("");

    const [newComp, setNewComp] = React.useState({
        name: "",
        roundName: "",
        eventId: "MMISL2022",
        description: "",
        level: "",
        location: "",
        id: uuidv4(),
        startDate: customE.startDate,
        startTime: customE.startTime,
        endTime: customE.endTime
    })

    useEffect(()=>{
        getLocations().then(()=>getEvent()).then(()=>setLoading(false))
    },[])

    const getLocations = async ()=>{
        const collectionReference = collection(db, "tenant", "root-05fr7", "event", "MMISL2022", "locations");
        const result = await getDocs(collectionReference)

        const l = result.docs.map((d) => {
            return d.data()
        })
        setLocations(l)
    }

    const getEvent = async () => {
        const d = doc(db, "tenant", "root-05fr7", "event", "MMISL2022")
        const result = await getDoc(d)
        setCustomeE(result.data())
        setComps(result.data().competitions.sort(sortComps))
    }

    const updateEvent = async () => {
        setLoading(true)
        const d = doc(db, "tenant", "root-05fr7", "event", "MMISL2022")
        await setDoc(d, customE, {merge: true})
        setLoading(false)
    }

    const deleteComp = async (id) => {
        let c = comps.filter((c)=>c.id !== id)
        c = c.sort(sortComps)
        setComps(c)
        setCustomeE({...customE, competitions: c})
    }

    const editCompetition = (id, field, event) => {
        const c = customE.competitions.find((c)=>c.id === id);
        c[field] = event.target.value;
        setComps([...customE.competitions].sort(sortComps))
    }

    const editNewComp = (field, event) => {
        newComp[field] = event.target.value;
        setNewComp({...newComp})
    }

    const sortComps = (a,b) => a.startDate + a.startTime < b.startDate + b.startTime ? -1 : 1;

    const cancelAdding = () => {
        setAddingComp(false)
    }

    const toggleAdding = () =>{
        if(!addingComp){
            setNewComp({
                name: "",
                roundName: "",
                eventId: "MMISL2022",
                description: "",
                level: "",
                location: "",
                id: uuidv4(),
                startDate: customE.startDate,
                startTime: customE.startTime,
                endTime: customE.endTime
            })
        }else{
            if(newComp.name.length === 0){
                setError("Name is required")
            }
            if(newComp.location.length === 0){
                setError("Location is required")
            }
            let c = comps
            comps.push(newComp)
            c = c.sort(sortComps)
            setComps(c)
            setCustomeE({...customE, competitions: c})
            setNewComp({
                name: "",
                roundName: "",
                eventId: "MMISL2022",
                description: "",
                level: "",
                location: "",
                id: uuidv4(),
                startDate: customE.startDate,
                startTime: customE.startTime,
                endTime: customE.endTime
            })
        }
        setAddingComp(!addingComp)

    }


    const setEVal = (field, event) => {
        customE[field] = event.target.value;
        setCustomeE({...customE})
    }

    //name, roundName, eventId = MMISL2022, description, level, location, id, startDate,startTime, endTime

    return loading ? <div>Loading...</div> :
        (
            <div style={{position:'relative'}}>
                <div className={'event-container'}>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Start Date</th>
                                <th>Start Time</th>
                                <th>End Date</th>
                                <th>End Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input type={"text"} value={customE.name} onChange={(e)=>{setEVal('name', e)}}/>
                                </td>
                                <td>
                                    <input type={"date"} value={customE.startDate} onChange={(e)=>{setEVal('startDate', e)}}/>
                                </td>
                                <td>
                                    <input type={"time"} value={customE.startTime} onChange={(e)=>{setEVal('startTime', e)}}/>
                                </td>
                                <td>
                                    <input type={"date"} value={customE.endDate} onChange={(e)=>{setEVal('endDate', e)}}/>
                                </td>
                                <td>
                                    <input type={"time"} value={customE.endTime} onChange={(e)=>{setEVal('endTime', e)}}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button style={{backgroundColor: 'green', width: '100px', marginTop: '0px', marginBottom: '8px'}} onClick={()=>updateEvent()}>Save</button>
                    <span style={{ color: 'red' }}>{error}</span>
                </div>

                <div className={'example-container'}>
                    <div>Example Event Display</div>
                    <div className={'example'}>
                        <div className={'example-left'}>
                            <div className={'day'}>
                                FRI
                            </div>
                            <div className={'start-time'}>
                                8:00p
                            </div>
                            <div className={'end-time'}>
                                9:00p
                            </div>
                        </div>
                        <div className={'example-center'}>
                        </div>
                        <div className={'example-right'}>
                            <div className={'name'}>
                               <span>Event Name</span> <span className={'level-pill'}>Level</span>
                            </div>

                            <div className={'round-name'}>Round Name</div>
                            <div className={'description'}>Description</div>

                        </div>
                    </div>
                </div>
                <div style={{marginTop:'30px'}} className={'mb-4 flex flex-row items-center justify-between'}>
                    <h3 style={{marginTop:"10px"}}>Sessions</h3>
                    <div className={'flex flex-row'}>
                        {
                            addingComp &&
                            <button style={{backgroundColor: 'red', width: '100px'}} onClick={()=>cancelAdding()}>Cancel</button>
                        }
                        <button className={'ml-2'} style={{backgroundColor: addingComp ? 'green' : '#3554c2', width: '200px'}} onClick={()=>toggleAdding()}>{addingComp ? 'Save' : 'Add'} Session</button>
                    </div>
                </div>
                <div className={'headers'}>
                    <div>Name</div>
                    <div>Round Name</div>
                    <div style={{marginLeft: '10px'}}>Description</div>
                    <div style={{marginLeft: '10px'}}>Level</div>
                    <div style={{width: '100px'}}>Location</div>
                    <div style={{marginLeft: '-10px'}}>Start Date</div>
                    <div>Start Time</div>
                    <div>End Time</div>
                </div>
                {addingComp && <div className={'event-row new'}>
                    <div>
                        <input type="text" value={newComp.name} onChange={(e)=> editNewComp('name', e)}/>
                    </div>
                    <div>
                        <input type="text" value={newComp.roundName} onChange={(e)=>editNewComp('roundName', e)}/>
                    </div>
                    <div>
                        <input type="text" value={newComp.description} onChange={(e)=>editNewComp('description', e)}/>
                    </div>
                    <div>
                        <input type="text" value={newComp.level} onChange={(e)=>editNewComp('level', e)}/>
                    </div>
                    <div>
                        <select value={newComp.location}  onChange={(e) => editNewComp('location', e)}>
                            <option value={''}>Select Location</option>
                                {locations.map((l) => {
                                    return <option value={l.name}>{l.name}</option>
                                }
                            )}
                        </select>
                    </div>
                    <div>
                        <input type="date" value={newComp.startDate} onChange={(e)=>editNewComp('startDate', e)}/>
                    </div>
                    <div>
                        <input type="time" value={newComp.startTime} onChange={(e)=>editNewComp('startTime', e)}/>
                    </div>
                    <div>
                        <input type="time" value={newComp.endTime} onChange={(e)=>editNewComp('endTime', e)}/>
                    </div>
                </div>
                }
                    {comps &&  comps.map(c => {
                        return (
                            <div  className={'event-row'} key={c.id}>
                                <div>
                                    <input type="text" value={c.name} onChange={(e)=> editCompetition(c.id, 'name', e)}/>
                                </div>
                                <div>
                                    <input type="text" value={c.roundName} onChange={(e)=>editCompetition(c.id, 'roundName', e)}/>
                                </div>
                                <div>
                                    <input type="text" value={c.description} onChange={(e)=>editCompetition(c.id, 'description', e)}/>
                                </div>
                                <div>
                                    <input type="text" value={c.level} onChange={(e)=>editCompetition(c.id, 'level', e)}/>
                                </div>
                                <div>
                                    <select value={c.location}  onChange={(e) => editCompetition(c.id, 'location', e)}>
                                        {locations.map(l=>{
                                            return <option key={l.name} value={l.name}>{l.name}</option>
                                        })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <input type={"date"} value={c.startDate} onChange={(e)=>editCompetition(c.id, 'startDate', e)}/>
                                </div>
                                <div>
                                    <input type={"time"} value={c.startTime} onChange={(e)=>editCompetition(c.id, 'startTime', e)}/>
                                </div>
                                <div>
                                    <input type={"time"} value={c.endTime} onChange={(e)=>editCompetition(c.id, 'endTime', e)}/>
                                </div>
                                <div className={'button-container'}>
                                    <button onClick={()=>deleteComp(c.id)}>-</button>
                                </div>
                            </div>
                        )
                    })
                }
        </div>
    )
}
