import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase/firebase';
import './Login.css'


export default function Login(){

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const usernameSignIn = () => {
        signInWithEmailAndPassword(auth, username, password)
    };

    return (
        <main>
            <h1>Admin Login</h1>
            <div className={"form"}>
                <input style={{color: "#000"}} type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                <input style={{color: "#000"}} type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button onClick={usernameSignIn}>Login</button>
            </div>
        </main>
    )
}

