import './App.css';
import {useState} from "react";
import Login from "./routes/login/Login";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase/firebase';
import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import Navbar from "./components/Navbar/Navbar";


function App() {

  const [user, setUser] = useState(null);

  onAuthStateChanged(auth, setUser);


  return (
    <div className="App">
      {user ? (
          <>
            <Navbar />
            <RouterProvider router={router} />
          </>
          ) : <Login />
      }
    </div>
  );
}

export default App;
