import React, {useEffect, useState} from 'react'
import {collection, doc, getDocs, setDoc, deleteDoc} from "firebase/firestore";
import {db} from "../../firebase/firebase";
import './Locations.css'

export default function Locations(event, setEvent){

    const [locations, setLocations] = useState([])
    const [newL, setNewL] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(()=> {
        getLocations()
    },[])

    const createLocation = () => {
        if(!newL){
            return
        }

        setLoading(true)
        setDoc(doc(db, "tenant", "root-05fr7", "event", "MMISL2022", "locations", newL),
                {
                    name: newL
                },
                {merge: true}
            );
        setLocations((val)=>[...val, {name: newL}])
        setLoading(false)
    }

    const deleteLocation = async (i) => {
        setLoading(true)
        await deleteDoc(doc(db, "tenant", "root-05fr7", "event", "MMISL2022", "locations", locations[i].name))
        setLocations(locations.filter((l, index) => index !== i))
        setLoading(false)
    }

    const getLocations = async ()=>{
        const collectionReference = collection(db, "tenant", "root-05fr7", "event", "MMISL2022", "locations");
        const result = await getDocs(collectionReference)

        const l = result.docs.map((d) => {
            return d.data()
        })
        setLocations(l)
        setLoading(false)

    }

    const addLocation = ()=>{
        if(newL.length > 0){
            createLocation()
            setNewL("")
        }
    }

    return(
        <>
            <div className={'location-container'}>
                {loading && <div className={'loading'}>Loading...</div>}
                {locations && locations.map((l, index) => {
                    return (
                        <div className={'location'} key={l.name}>
                            <span>{l.name}</span>
                            <div className={'minus'} onClick={()=>deleteLocation(index)}><div className={'minus-text'}>-</div></div>
                        </div>
                    )
                })}
                <input className={'new-location'} value={newL} onChange={(e)=> setNewL(e.target.value)} type={'text'} placeholder={'Add Location'} />
                <button onClick={addLocation}>+Add</button>
            </div>

        </>
    )
}
